@import "styles/partials";

.page-title-small {
  @include mq($from: tablet) {
    left: $padding-side--tablet;
  }
  left: $padding-side;
  overflow: hidden;
  position: absolute;
  h1 {
    line-height: 1;
    margin: 0;
    margin-bottom: -.11em;
    transform: translateY(-.11em);
    padding: 0.03em 0;
  }
  &.is-on-overlay {
    @include mq($from: tablet) {
      bottom: $padding-side--tablet;
    }
    bottom: 40px;
    position: fixed;
  }
  &.is-on-page {
    @include mq($from: tablet) {
      top: 96px;
    }
    top: 96px;
    position: fixed;
    z-index: $z-page-title-small;
  }
}

.page-title-small__inner {
  display: flex;
  transition: transform 0.3s $a-button-move-f;
  transform: translateY(105%);
  .page-title-small.is-open & {
    transform: translateY(0%);
  }
}