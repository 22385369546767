@import "styles/partials";

.lobby {
  position: relative;
}

.scroll.lobby__scroll {
  position: relative;
  pointer-events: none;
  z-index: $z-lobby-scroll;
}

.lobbly__fixed-container {
  position: relative;
}

.lobby__block {
  pointer-events: none;
  position: relative;
  .lobbly__sticky-container & {
    padding: 20vh 0;
  }
}

.lobby__first {
  position: relative;
}

.lobby__logo {
  @include mq($from: desktop) {
    padding: 0;
    height: 100vh;
  }
  box-sizing: border-box;
  align-items: center;
  display: flex;
  height: 89vh;
  justify-content: center;
  left: 0;
  padding: $padding-side;
  position: absolute;
  top: 0;
  width: 100%;
  svg {
    height: auto;
    max-width: 100%;
    width: 890px;
  }
  filter: blur(10px);
  opacity: 0;
  transition: $a-transition-blur;
  .is-init & {
    filter: blur(0);
    opacity: 1;
  }
}

.lobby__intro {
  @include wrapper();
  @include mq($from: desktop) {
    padding-top: 88vh;
  }
  // margin-top: -12vh;
  padding-top: 76vh;
  position: relative;
  text-indent: 20%;
  transform: translateY($a-block-y);
  opacity: 0;
  transition: transform 0.6s $easeOutCubic .5s;
  .is-init & {
    transform: translateY(0);
    opacity: 1;
  }
}

.lobbly__sticky-container {
  position: relative;
}

.lobby__void {
  height: 25vh;
  background: rgba(red, 0.1);
}

// SECTION
.lobby-section {
  padding-top: 50vh;
  padding-bottom: 100vh;
  z-index: $z-lobby-block;
  &.no-padding {
    padding-top: 0;
  }
}

.lobby-section__inner {
  position: relative;
}

.lobby-section__image-wall-spacer {
  @include mq($from: desktop) {
    height: 80vh;
  }
  height: 60vh;
}

.lobby__sponsors {
  @include wrapper();
  @include mq($from: tablet) {
    margin-top: $padding-sections--tablet;
  }
  margin-top: $padding-sections;
  max-width: 100%;
  width: 540px;
  img {
    display: block;
    height: auto;
    margin: auto;
    width: auto;
    max-width: 100%;
  }
}

.lobby-menu-blocker {
  height: 100vh;
}

.lobby-hotel-trigger {
  height: 80vh;
}

.lobbly-hotel-scrub {
  position: relative;
}

// SCHEDULE BUTTON
.lobby-schedule-button {
  @include mq($from: desktop) {
    font-size: 0.8em;
  }
  bottom: 0;
  font-size: 0.8em;
  position: fixed;
  right: 0;
  z-index: $z-speaker-schedule;
  transition: 0.3s opacity $easeInOutSine, 0.3s filter $easeInOutCubic;
  a,
  button {
    transform: rotate(-90deg) translate(100%, -100%);
    transform-origin: right top;
  }
  .is-overlay-open & {
    opacity: 0;
    filter: blur(10px);
  }
}

// DOTNAV
.lobby-dotnav {
  @include mq($from: desktop) {
    display: flex;
    right: 8px;
  }
  bottom: 0;
  display: none;
  margin: auto;
  position: fixed;
  right: 0px;
  pointer-events: none;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s $easeInOutSine;
  // top: 0;
  .is-overlay-open & {
    opacity: 0;
    filter: blur(10px);
  }
}

.lobby-dotnav__item {
  @include mq($from: tablet) {
    height: 16px;
    width: 16px;
  }
  box-sizing: content-box;
  margin: 8px 0;
  height: 12px;
  width: 16px;
  &:before {
    background: $c-black;
    border-radius: 50%;
    content: "";
    // cursor: pointer;
    display: block;
    height: 8px;
    width: 8px;
  }
  &.is-current {
    &:before {
      background: $c-green;
    }
  }
}
