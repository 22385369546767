@import "styles/partials";

.preloader {
  @include mq($from: tablet) {
    padding: $padding-side--tablet;
  }
  align-items: center;
  box-sizing: border-box;
  display: flex;
  font-family: $font-family-hl;
  height: 100vh;
  left: 0;
  justify-content: flex-end;
  padding: $padding-side;
  pointer-events: none;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: $z-preloader;
  opacity: 0;
  transition: opacity 0.3s $easeInOutCubic;
  &.is-font-loaded {
    opacity: 1;
  }
  &.is-complete {
    opacity: 0;
  }
}

.preloader-bg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  > div {
    background: $c-green;
    opacity: 1;
    transition: all $easeInSine;
    .preloader.is-complete & {
      opacity: 0;
      transform-origin: left center;
    }
  }
}

.preloader-number {
  @include mq($from: tablet) {
    margin-right: $padding-side--tablet*2;
  }
  @include fluid-typo(64px, 240px);
  color: $c-white;
  margin-right: -$padding-side/2;
  transition: opacity $easeInSine, transform .6s $easeInCubic;
  .preloader.is-complete & {
    opacity: 0;
    transform-origin: left center;
    transform: translateY(-300%);
  }
  span {
    display: inline-flex;
    justify-content: center;
    line-height: 1;
    width: .95em;
    &:first-child {
      justify-content: flex-end;
    }
  }
}

.preloader-number__inner {
  @include mq($from: tablet) {
    padding: $padding-side--tablet;
  }
  line-height: 1;
  position: relative;
  padding: $padding-side;
}

.preloader-number-cross {
  &.is-one {
    top: 0;
    left: 0;
  }
  &.is-two {
    top: 0;
    right: 0;
  }
  &.is-three {
    bottom: 0;
    right: 0;
  }
  &.is-four {
    bottom: 0;
    left: 0;
  }
}

.preloader-label {
  @include mq($from: tablet) {
    height: 100%;
  }
  @include typography-headline();
  align-items: center;
  color: $c-white;
  top: 0;
  display: flex;
  height: 70%;
  left: 0;
  justify-content: center;
  position: absolute;
  padding-left: 32px;
  &:before {
    background: $c-white;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    width: 24px;
  }
  span {
    display: block;
  }
}

.preloader__markers {
  @include mq($from: tablet) {
    padding: $padding-side--tablet/2;
  }
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  padding: $padding-side/2;
}

.preloader-corners {
  body.is-mobile & {
    display: none;
  }
  box-sizing: border-box;
  height: 100%;
  left: 0;
  opacity: 1;
  transition: opacity .1s linear;
  position: relative;
  top: 0;
  width: 100%;
  > div {
    position: absolute;
    &:before {
      background: $c-white;
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      width: 16px;
    }
    &:after {
      background: $c-white;
      content: "";
      display: block;
      height: 16px;
      position: absolute;
      width: 1px;
    }
    &:nth-child(1) {
      left: 0;
      top: 0;
      &:before {
        left: 0;
        top: 0;
      }
      &:after {
        left: 0;
        top: 0;
      }
    }
    &:nth-child(2) {
      @include mq($until: tablet) {
        display: none;
      }
      right: 0;
      top: 0;
      &:before,
      &:after {
        right: 0;
        top: 0;
      }
    }
    &:nth-child(3) {
      bottom: 0;
      right: 0;
      &:after {
        right: 0;
        bottom: 0;
      }
      &:before {
        bottom: 0;
        left: -16px;
      }
    }
    &:nth-child(4) {
      bottom: 0;
      left: 0;
      &:after,
      &:before {
        bottom: 0;
        left: 0;
      }
    }
  }
}

.preloader-number-cross {
  &:before,
  &:after {
    background: $c-white !important;
  }
}