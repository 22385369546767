@import "styles/partials";
@import "styles/consent";

body {
  @include font-size("p");
  &.is-fixed {
    position: fixed;
    left: 0;
    right: 0;
  }
}

.app-permission {
  position: fixed;
  bottom: 0;
  height: 20px;
  width: 100%;
  z-index: 10000;
}

.app-footer-container {
  height: 100vh;
  position: relative;
  width: 100%;
}

.app-content {
  min-height: 100vh;
}

h1 {
  @include typography-headline();
  @include fluid-typo($font-size-headline, $font-size-headline--max);
}

h2 {
  @include typography-headline();
  @include fluid-typo($font-size-headline, $font-size-headline--max);
}

h3 {
  @include typography-headline();
  @include font-size("h3");
}

h5 {
  @include typography-headline();
  @include font-size("h5");
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

button {
  cursor: pointer;
  font-size: 1em;
  padding: 0;
}

p {
  @include font-size("p");
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

img {
  height: auto;
  max-width: 100%;
}
