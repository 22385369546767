body {
  font-family: "Univers", "Helvetica Neue", sans-serif;
  font-size: 16px;
  hyphens: auto;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "titling";
  src: url("./static/fonts/TitlingGothicFB-Wide-Bold-webfont.ttf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Univers";
  src: url("./static/fonts/UniversLTW04-63BoldExtended.eot");
  src: url("./static/fonts/UniversLTW04-63BoldExtended.eot?#iefix") format("embedded-opentype"),
    url("./static/fonts/UniversLTW04-63BoldExtended.woff2") format("woff2"),
    url("./static/fonts/UniversLTW04-63BoldExtended.woff") format("woff"),
    url("./static/fonts/UniversLTW04-63BoldExtended.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}
