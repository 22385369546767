$font-family-hl: "titling", sans-serif;

$font-size-headline: 24px;
$font-size-headline--max: 42px;

$font-sizes: (
  "base": 16px 19px,
  "p": 14px 14px,
  "h1": 24px 42px,
  "h2": 24px 42px,
  "h3": 24px 42px,
  "h5": 24px 24px,
  "lobby-intro": 24px 42px,
  "lobby-overlay-hl": 24px 42px,
  "menu-item": 19px 19px,
  "schedule-time": 24px 24px,
);