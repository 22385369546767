@import "styles/partials.scss";

.menu-item {
  @include mq($from: tablet) {
    width: 25%;
  }
  box-sizing: border-box;
  padding-left: $padding-side;
  padding-right: $padding-side;
  text-align: center;
  transition: opacity 0.4s $easeInOutCubic 0s;
  opacity: 0;
  .menu.is-open & {
    opacity: 1;
    transition-delay: .4s;
    &:nth-child(2) {
      transition-delay: .6s;
    }
    &:nth-child(3) {
      transition-delay: .8s;
    }
    &:nth-child(4) {
      transition-delay: 1s;
    }
  }
  .hotel-title-num {
    font-size: 1em;
  }
}

.menu-item__inner {
  height: 100%;
  position: relative;
  transition-duration: inherit;
  transition-delay: inherit;
  width: 100%;
  [data-is-current=true] & {
    pointer-events: none;
  }
}

.menu-item__link {
  font-size: 1em;
  padding: 0;
  transition-delay: inherit;
  width: 100%;
}

.menu-item__title {
  @include mq($from: tablet) {
    justify-content: center;
    margin-bottom: 32px;
    margin-top: -10px;
  }
  display: flex;
  font-size: 16px;
  height: 23px;
  margin-bottom: 16px;
  margin-top: 16px;
  transform: translateY(-200%);
  transition: transform 0s $easeOutBack 1s;
  overflow: hidden;
  .menu.is-open & {
    transform: translateY(0);
    transition-duration: .6s;
    transition-delay: inherit;
  }
  span {
    @include typography-headline();
    display: block;
    color: $c-white;
    line-height: 1.1;
    &:last-child {
      color: $c-black;
    }
  }
}

.menu-item__title-area {
  @include mq($from: tablet) {
    font-size: 16px;
    transform: translateY(0.21em);
  }
  @include mq($from: wide) {
    font-size: 19px;
    transform: translateY(0.07em);
  }
  font-size: 19px;
  position: relative;
  transform: translateY(0.07em);
  transition: transform $a-button-move-d $a-button-move-f;
  .menu-item:hover &,
  .menu-item[data-is-current=true] & {
    transform: translateY(-1em);
  }
}

.menu-item__visual {
  @include mq($until: tablet) {
    display: none;
  }
  position: relative;
  transform: scale(0.3) translateY(50%);
  transition: transform 0s $easeOutCubic.6s;
  .menu.is-open & {
    transform: scale(1) translateY(0%);
    transition-duration: .4s;
    transition-delay: inherit !important;
  }
  &:before {
    content: "";
    display: block;
    padding-bottom: 56.25%;
    width: 100%;
  }
  img {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    transition: transform 0.3s $easeOutCubic;
    .menu-item:hover & {
      transform: scale(1.1);
    }
  }
}

.menu-item__link {
  cursor: pointer;
  display: block;
  position: relative;
  text-decoration: none;
}

.menu-item__cross {
  @include mq($until: desktop) {
    display: none;
  }
  opacity: 0;
  transition: all 0.3s $easeOutCubic;
  position: absolute;
  bottom: 0;
  &.is-left {
    left: 0;
    transform: translate(16px, 16px);
    top: -16px;
  }
  &.is-right {
    right: 0;
    transform: translate(-16px, 16px);
    top: -16px;
  }
  &.is-bottom-left {
    bottom: 0;
    left: 0;
    transform: translate(16px, -16px);
  }
  &.is-bottom-right {
    bottom: 0;
    right: 0;
    transform: translate(-16px, -16px);
  }
  .menu-item:hover & {
    opacity: 1;
    transform: translateX(0);
  }
}
