@mixin section-padding() {
  @include mq($from: tablet) {
    padding-top: $padding-sections--tablet;
    padding-bottom: $padding-sections--tablet;
  }
  @include mq($from: desktop) {
    padding-top: $padding-sections--desktop;
    padding-bottom: $padding-sections--desktop;
  }
  padding-top: $padding-sections;
  padding-bottom: $padding-sections;
}