@mixin wrapper($noMax: false) {
  @include mq($from: tablet) {
    padding-left: $padding-side--tablet;
    padding-right: $padding-side--tablet;
  }
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: $padding-side;
  padding-right: $padding-side;
  width: 100%;
  @if ($noMax == false) {
    max-width: $wrapper-max-width;
  }
}

.wrapper {
  @include wrapper();
}

.wrapper--no-max {
  @include wrapper($noMax: true);
}