@mixin grid-row() {
  @include mq($from: tablet) {
    display: flex;
    margin-left: -$padding-side--tablet/2;
    margin-right: -$padding-side--tablet/2;
  }
  margin-left: -$padding-side/2;
  margin-right: -$padding-side/2;
}

.grid-row {
  @include grid-row();
}

@mixin grid-cell() {
  @include mq($from: tablet) {
    padding-left: $padding-side--tablet/2;
    padding-right: $padding-side--tablet/2;
  }
  box-sizing: border-box;
  padding-left: $padding-side/2;
  padding-right: $padding-side/2;
}

.grid-cell {
  @include grid-cell();
}
