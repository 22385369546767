$z-teaser-3d: 0;
$z-scroll: 1;
$z-lobby-block: 20;

$z-lobby-dotnav: 40;
$z-hotel-title: 50;
$z-hotel-wrapper: 55;
$z-hotel-canvas: 55;
$z-lobby-3d: 60;
$z-lobby-scroll: 65;
$z-lobby-overlay: 70;
$z-lobby-3d-overlay: 75;
$z-lobby-overlay-bottom: 78;
$z-topbar: 80;
$z-page-title-small: 85;
$z-speaker-schedule: 95;
$z-footer: 90;
$z-transition: 110;
$z-menu: 120;
$z-preloader: 120;