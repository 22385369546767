@import "styles/partials";

.image-wall {
  // background: rgba(0,0,0,0.1);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.image-wall__wrapper {
  @include wrapper();
  height: 100%;
}

.image-wall__images {
  position: relative;
  height: 100%;
  width: 100%;
  // overflow-x: hidden;
}

.image-wall__image {
  @include mq($from: tablet) {
    width: 20vw;
  }
  @include mq($from: desktop) {
    max-width: 20vw;
    width: 400px;
  }
  justify-content: center;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 40vw;
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
    width: 100%;
  }
}

.image-wall__image-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-wall__image-img {
  max-width: initial;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}