@import "styles/partials";

.topbar {
  @include mq($from: tablet) {
    align-items: center;
    height: 66px;
    padding: $padding-side--tablet;
    justify-content: flex-end;
  }
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  height: 80px;
  left: 0;
  justify-content: space-between;
  padding: $padding-side;
  position: fixed;
  top: 0;
  right: 0;
  z-index: $z-topbar;
  body.is-topbar-transparent & {
    background-color: rgba(255,255,255,0);
  }
  &:before {
    @include mq($from: tablet) {
      height: 35px;
    }
    background-color: $c-white;
    content: '';
    height: 45px;
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
  }
  &:after {
    @include mq($from: tablet) {
      height: 35px;
      top: 35px;
    }
    background: linear-gradient(180deg, $c-white 0, rgba($c-white, 0));
    content: '';
    display: block;
    left: 0;
    height: 45px;
    position: absolute;
    top: 45px;
    width: 100%;
    transition: height .6s $easeInOutCubic;
    pointer-events: none;
  }
}

.topbar__button-nav {
  @include mq($from: tablet) {
    font-size: 18px;
  }
  font-size: 13px;
  padding: 0;
  position: relative;
  z-index: 1;
}

.topbar__button-nav-inner {
  display: flex;
  margin-left: -$padding-side/2;
  margin-right: -$padding-side/2;
  button {
    @include mq($from: tablet) {
      padding-left: $padding-side--tablet/2;
      padding-right: $padding-side--tablet/2;
    }
    padding-left: $padding-side/2;
    padding-right: $padding-side/2;
  }
}

// LOGO
.topbar-logo {
  @include mq($until: tablet) {
    display: none;
  }
  bottom: 0;
  height: 24px;
  left: $padding-side--tablet;
  margin: auto;
  position: absolute;
  top: 0;
  z-index: 1;
  #logo-area-green {
    transform: translateY(100%);
    transition: transform $a-button-move-d $a-button-move-f;
    .topbar-logo-link:hover & {
      transform: translateY(0);
    }
  }
  #logo-hotel {
    transition: transform $a-button-move-d $a-button-move-f;
  }
  #logo-hotel-bottom {
    transform: translateY(100%);
    transition: transform $a-button-move-d $a-button-move-f;
  }
  &:hover {
    #logo-area-green {
      transform: translateY(0);
    }
    #logo-hotel {
      transform: translateY(-100%);
    }
    #logo-hotel-bottom {
      transform: translateY(0);
    }
  }
  
}

.topbar-logo-mobile {
  @include mq($from: tablet) {
    display: none;
  }
  display: block;
  height: 52px;
  left: 0;
  z-index: 1;
}