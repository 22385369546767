@import "styles/partials.scss";

.schedule-future {
  @include section-padding();
  border-top: 1px solid $c-black;
}

.schedule-future__inner {
  @include mq($from: desktop) {
    flex-direction: row;
  }
  display: flex;
  flex-direction: column;
  > * {
    width: 100%;
  }
  p {
    margin-top: 0;
  }
  h5 {
    span {
      display: block;
    }
  }
}

.schedule__teasers {
  @include mq($from: tablet) {
    margin-top: 48px;
  }
}

.schedule-rooms {
  border-top: 1px solid $c-black;
}

.schedule-rooms__inner {
  width: 680px;
  max-width: 100%;
}

.schedule-room {
  @include section-padding();
  border-top: 1px solid $c-black;
  h5 {
    margin: 0 0;
  }
  span {
    font-weight: bold;
    display: inline-block;
    margin-bottom: 0.5em;
    @include typography-headline();
  }
  p {
    margin-top: 0 !important;
  }
  .grid-row {
    @include mq($from: desktop) {
      flex-direction: row;
    }
    flex-direction: column;
  }
  .grid-cell {
    width: 100%;
  }
}

.schedule-room__inner {
  position: relative;
}

.schedule-room__cross {
  @include mq($from: desktop) {
    display: block;
    left: $padding-side--tablet/2;
  }
  bottom: 0;
  display: none;
  position: absolute;
}

.schedule-room__cross--top {
  @include mq($from: tablet) {
    right: $padding-side--tablet/2;
  }
  top: 0;
  right: $padding-side/2;
  position: absolute;
}
