@import "styles/partials.scss";

.lobby-overlay-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: $z-lobby-overlay-bottom;
}

.lobby-overlay-bottom__marquee {
  @include mq($from: tablet) {
    padding: $padding-side--tablet;
    max-width: 52vw;
    width: 512px;
  }
  @include mq($from: desktop) {
  }
  bottom: 0;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  padding: $padding-side;
  opacity: 0;
  transition: opacity 0s $easeInOutSine;
  pointer-events: none;
  width: 100%;
  .lobby-overlay-bottom.is-in & {
    opacity: 1;
    transition-duration: 0.6s;
  }
}