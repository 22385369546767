@import "styles/partials";

.lobby-overlay {
  align-items: center;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: $z-lobby-overlay;
}

.lobby-overlay__wrapper {
  @include mq($from: desktop) {
    padding-left: 0;
    padding-right: 0;
  }
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  width: 920px;
  transform: scale(1.1);
  transition: transform $easeOutCubic .6s;
  padding-left: $padding-side;
  padding-right: $padding-side;
  .lobby-overlay.is-in & {
    transform: scale(1);
  }
}

.lobby-overlay__inner {
  @include mq($from: desktop) {
    padding-left: 0;
    padding-right: 0;
  }
  padding-left: $padding-side;
  padding-right: $padding-side;
  position: relative;
  transition-duration: inherit;
  hyphens: none;
}

.lobby-overlay__cross {
  opacity: 0;
  transition: opacity .6s $easeInOutCubic;
  .lobby-overlay.is-in & {
    opacity: 1;
  }
  &.is-cross-one {
    @include mq($until: tablet) {
      right: -$padding-side/2;
    }
    right: 0px;
    top: 0px;
  }
  &.is-cross-two {
    @include mq($from: tablet) {
      bottom: 0px;
      left: 0px;
      top: initial;
    }
    left: -$padding-side/2;
    top: 0px;
  }
}

.lobby-overlay__content {
  @include mq($from: tablet) {
    padding: $padding-side--tablet $padding-side--tablet*2;
  }
  padding: $padding-side*2 0 $padding-side*2;
  transition-duration: inherit;
}

.lobby-overlay__headline {
  @include font-size("lobby-overlay-hl");
  margin: 0;
  text-align: center;
  transform: translateY(-.5em) scale(1.2);
  transition: all .6s $easeOutCubic;
  opacity: 0;
  .lobby-overlay.is-in & {
    transform: translateY(-.22em) scale(1);
    opacity: 1;
    transition-delay: 0.3s;
  }
}

.lobby-overlay__button {
  @include mq($until: tablet) {
    button {
      width: 100%;
    }
  }
  @include mq($from: tablet) {
    margin-top: $padding-side--tablet;
  }
  margin-top: $padding-side;
  text-align: center;
  transition: all .6s $easeOutCubic;
  transform: scale(1.2) translateY(20%);
  opacity: 0;
  > * {
    display: inline-block;
    margin: auto;
  }
  .lobby-overlay.is-in & {
    transform: scale(1);
    opacity: 1;
    pointer-events: initial;
    transition-delay: .6s;
  }
}
