@import "styles/partials";

@keyframes line {
  0% { transform: scaleX(0); transform-origin: 0% 0%; }
  20% { transform: scaleX(1); transform-origin: 0% 0%; }
  80% { transform: scaleX(1); transform-origin: 100% 0%; }
  100% { transform: scaleX(0); transform-origin: 100% 0%; }
}

.teaser {
  position: relative;
  &:before {
    background: $c-black;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    opacity: 0;
    transform: scaleX(0);
    transform-origin: 0% 100%;
    transition: transform 1s $easeInOutCubic, opacity 1s $easeInOutSine;
    width: 100%;
  }
  .cross {
    opacity: 0;
    // transform: rotate(90deg);
  }
  &:nth-child(odd) {
    &:before {
      transform-origin: 100% 0%;
    }
    .cross {
      // transform: rotate(-90deg);
    }
  }
  &.is-in {
    &:before {
      opacity: 1;
      transform: scaleX(1);
    }
    .cross {
      opacity: 1;
      transform: rotate(0);
      transition: opacity 1s $easeInOutCubic, transform 1s $easeOutCubic;
    }
  }
}

.teaser__wrapper {
  @include wrapper();
  @include section-padding();
}

.teaser__row {
  @include mq($from: desktop) {
    flex-direction: row;
    margin-left: -$padding-side--tablet/2;
    margin-right: -$padding-side--tablet/2;
  }
  display: flex;
  flex-direction: column;
  margin-left: -$padding-side/2;
  margin-right: -$padding-side/2;
  position: relative;
}

.teaser-title {
  margin-top: 0;
}

// BLOCK
.teaser-block {
  @include mq($from: desktop) {
    padding: $padding-side--tablet/2;
    padding: $padding-side--tablet/2;
  }
  box-sizing: border-box;
  padding: $padding-side/2;
  padding: $padding-side/2;
  position: relative;
}

.teaser-block--image {
  position: relative;
  z-index: 10;
  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.teaser-block--copy {
  display: flex;
  flex-direction: column;
  h5 {
    margin-bottom: auto;
  }
  .teaser-block__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  h5, p, a, img {
    opacity: 0;
    transition: opacity 0s $easeOutSine;
    .teaser.is-in & {
      opacity: 1;
      transition-duration: 1s;
      transition-delay: 0.3s;
      &:nth-child(2) {
        transition-delay: 0.5s;
      }
      &:nth-child(3) {
        transition-delay: 0.7s;
      }
      &:nth-child(4) {
        transition-delay: 0.9s;
      }
    }
  }
}

.teaser-block-button {
  pointer-events: initial;
}

// EXHIBITIONS

