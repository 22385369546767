@import "styles/partials.scss";

.marquee {
  overflow: hidden;
  width: 100%;
  color: transparent;
  background-image: linear-gradient(
    90deg,
    rgba($c-black, 0) 0%,
    rgba($c-black, 1) 10%,
    rgba($c-black, 1) 90%,
    rgba($c-black, 0) 100%,
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.marquee__inner {
  display: flex;
  flex-wrap: nowrap;
}

.marquee__el {
  @include typography-headline();
  font-size: 12px;
  white-space: nowrap;
}