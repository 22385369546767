@import "styles/partials";

.menu {
  height: var(--win-height, 100vh);
  left: 0;
  pointer-events: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  // transition just to make mobile VP changes not so hard
  transition: height .2s $easeInOutCubic;
  width: 100%;
  z-index: $z-menu;
  &.is-open {
    pointer-events: initial;
    transition-delay: 0s;
  }
  &.is-footer {
    position: relative;
    z-index: 0;
    height: 100vh;
  }
  &.is-footer-fixed {
    position: fixed;
  }
}

.menu-close {
  @include mq($from: tablet) {
    right: $padding-side--tablet*3;
    top: $padding-side--tablet*3;
    height: 80px;
    width: 80px;
  }
  cursor: pointer;
  position: absolute;
  right: $padding-side;
  top: $padding-side;
  height: 40px;
  opacity: 0;
  transform: rotate(45deg);
  transition: opacity .3s $easeOutCubic;
  width: 40px;
  z-index: 10;
  .menu.is-open & {
    opacity: 1;
  }
  &:before,
  &:after {
    @include mq($from: tablet) {
      width: 72px;
    }
    background: $c-white;
    content: '';
    display: block;
    height: 2px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    transition: width .2s $easeOutCubic, height .2s $easeOutCubic;
    width: 40px;
  }
  &:after {
    @include mq($from: tablet) {
      height: 72px;
    }
    width: 2px;
    height: 40px;
  }
  &:hover {
    &:before {
      @include mq($from: tablet) {
        width: 80px;
      }
    }
    &:after {
      @include mq($from: tablet) {
        height: 80px;
      }
    }
  }
}

.menu-inner {
  @include mq($from: tablet) {
    padding: $padding-side--tablet;
  }
  box-sizing: border-box;
  height: 100%;
  padding: $padding-side;
  width: 100%;
}

.menu-inner__markers {
  bottom: $padding-side--tablet/2;
  left: $padding-side--tablet/2;
  position: absolute;
  right: $padding-side--tablet/2;
  top: $padding-side--tablet/2;
}

// corners
.menu-inner-corners {
  body.is-mobile & {
    display: none;
  }
  height: 100%;
  left: 0;
  position: absolute;
  opacity: 0;
  transition: opacity .1s linear;
  top: 0;
  width: 100%;
  .menu.is-open & {
    opacity: 1;
  }
  .menu.isNoTrans & {
    transition: none !important;
  }
  > div {
    position: absolute;
    &:before {
      background: $c-white;
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      width: 16px;
    }
    &:after {
      background: $c-white;
      content: "";
      display: block;
      height: 16px;
      position: absolute;
      width: 1px;
    }
    &:nth-child(1) {
      left: 0;
      top: 0;
      &:before {
        left: 0;
        top: 0;
      }
      &:after {
        left: 0;
        top: 0;
      }
    }
    &:nth-child(2) {
      @include mq($until: tablet) {
        display: none;
      }
      right: 0;
      top: 0;
      &:before,
      &:after {
        right: 0;
        top: 0;
      }
    }
    &:nth-child(3) {
      bottom: 0;
      right: 0;
      &:after {
        right: 0;
        bottom: 0;
      }
      &:before {
        bottom: 0;
        left: -16px;
      }
    }
    &:nth-child(4) {
      bottom: 0;
      left: 0;
      &:after,
      &:before {
        bottom: 0;
        left: 0;
      }
    }
  }
}

.menu-inner-lines {
  @include mq($until: desktop) {
    display: none;
  }
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  opacity: 0;
  transition: opacity .1s linear;
  .menu.is-open & {
    opacity: 1;
  }
  .menu.isNoTrans & {
    transition: none !important;
  }
  &:before,
  &:after {
    @include mq($from: wide) {
      width: 32px;
    }
    background-color: $c-white;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    height: 1px;
    top: 0;
    width: 16px;
  }
  &:after {
    left: initial;
    right: 0;
  }
}

// title
.menu-inner-title {
  @include mq($from: tablet) {
    margin: $padding-side--tablet * 2 $padding-side--tablet;
  }
  @include typography-headline();
  @include fluid-typo(42px, 188px);
  color: $c-white;
  line-height: 1;
  transform: translate(-0.07em, -0.1em);
  opacity: 0;
  overflow: hidden;
  margin: $padding-side * 5 0;
  position: relative;
  transform: scale(0.9);
  transform-origin: left top;
  transition: opacity .6s $easeInOutCubic;
  .menu.is-open & {
    opacity: 1;
    transition-delay: .3s;
  }
  .menu.isNoTrans & {
    transition: none !important;
  }
  // div {
  //   opacity: 0;
  //   transform: translateY(110%);
  //   transition: all .3s $easeOutCubic;
  //   .menu.is-open & {
  //     opacity: 1;
  //     transform: translateY(0%);
  //     transition-delay: .2s;
  //   }
  // }
}

// menu
.menu-inner-menu {
  @include mq($from: tablet) {
    flex-direction: row;
    top: 50%;
    padding-left: 24px;
    padding-right: 24px;
  }
  @include mq($from: wide) {
    padding: 0;
    width: 92%;
  }
  box-sizing: border-box;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  justify-content: center;
  margin: 0 auto 0;
  position: absolute;
  right: 0;
  top: 0%;
  width: auto;
}

// legal
.menu-legal {
  @include mq($from: tablet) {
    bottom: $padding-side--tablet/2;
    justify-content: center;
    flex-direction: row;
  }
  align-items: center;
  bottom: 40px;
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  left: $padding-side/2;
  right: $padding-side/2;
  a, button {
    @include mq($from: tablet) {
      padding: $padding-side--tablet/2;
      padding: $padding-side--tablet/2;
    }
    align-self: center;
    font-size: 12px;
    padding: $padding-side/2;
  }
}