@import "styles/partials";

a,
button {
  &.button-solid {
    @include mq($until: tablet) {
      font-size: 0.9em;
    }
    @include typography-headline;
    background: $c-black;
    cursor: pointer;
    color: $c-white;
    display: inline-block;
    font-size: 1em;
    hyphens: none;
    text-decoration: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    .button-solid-area {
      background: $c-green;
      height: 100%;
      left: 0;
      position: absolute;
      transform: scaleY(0);
      transform-origin: left bottom;
      transition: $a-button-move-d $a-button-move-f;
      top: 0;
      width: 102%;
      z-index: 0;
    }
    &:hover {
      .button-solid-area {
        transform: scaleX(1);
      }
    }
    span {
      display: block;
    }
    // span {
    //   display: block;
    //   height: 100%;
    //   position: relative;
    //   z-index: 1;
    //   width: 100%;
    // }
    &.is-small {
      font-size: 0.9em;
    }
  }
}

.button-solid__move-el {
  transition: $a-button-move-d $a-button-move-f;
  .button-solid:hover & {
    transform: translateY(-100%);
  }
}

.button-solid__el {
  background: $c-black;
  box-sizing: border-box;
  height: 100%;
  padding: 16px 20px;
  text-align: center;
  width: 100%;
  &:last-child {
    background: $c-green;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(100%);
  }
  .button-solid.is-small & {
    padding: 8px 20px;
  }
}
