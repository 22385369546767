@import "styles/partials.scss";

.page-header {
  @include mq($from: tablet) {
    padding-top: 32vh;
    padding-bottom: $padding-sections--tablet;
  }
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100vh;
  padding-top: 28vh;
  padding-bottom: $padding-sections;
  position: relative;
  opacity: 0;
  transform: translateY($a-block-y);
  transition: transform 0.6s $easeOutSine, opacity 0.6s $easeInOutCubic;
  &.is-in {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.3s;
  }
}

.page-header__inner {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  flex-direction: column;
  transition: inherit;
  @include mq($from: desktop) {
    .app.page--salon & {
      align-items: flex-start;
      flex-direction: row;
      justify-content: initial;
    }
  }
}

.page-header-headline {
  @include mq($from: desktop) {
    .app.page--salon & {
      margin-bottom: 0;
      width: 50%;
    }
    .app.page--studio & {
      width: 66.66%;
    }
  }
  @include mq($from: wide) {
    .app.page--studio & {
      width: 75%;
    }
  }
  margin-bottom: 40px;
  h2 {
    margin: 0;
    max-width: $wrapper-max-width;
    text-indent: 7.5%;
    span {
      display: block;
      + span {
        @include mq($from: tablet) {
          margin-top: 24px;
        }
        margin-top: 16px;
      }
    }
  }
}

.page-header__content {
  @include mq($from: desktop) {
    flex-direction: row;
  }
  flex-direction: column;
  transition: inherit;
}

.page-header-grid-cell {
  .image2k-panels {
    max-width: 100%;
  }
  > img {
    &.is-hidden {
      @include mq($from: desktop) {
        display: block;
      }
      display: none;
      opacity: 0;
    }
  }
  opacity: 1;
  transition: inherit;
  .page-header.is-in & {
    opacity: 1;
  }
  &:nth-child(1) {
    transition-delay: 0.7s;
  }
  &:nth-child(2) {
    transition-delay: 0.9s;
  }
  &:nth-child(3) {
    transition-delay: 1.1s;
  }
}

.page-header-sponsors {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 380px;
}

// PAGE SPECIFIC HACKING
.page-header--exhibitions {
  .page-header__content {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .page-header-grid-cell {
    @include mq($from: tablet) {
      width: 50%;
    }
    @include mq($from: desktop) {
      width: 33.33%;
      &:first-child {
        .page--studio & {
          margin-right: 33.33%;
        }
      }
    }
    @include mq($from: wide) {
      width: 25%;
      &:first-child {
        margin-left: 25%;
        .page--studio & {
          margin-left: 0;
          margin-right: 25%;
        }
      }
      &:nth-child(2) {
        .page--studio & {
          margin-left: 25%;
        }
      }
    }
    &:first-child {
      @include mq($until: tablet) {
        margin-bottom: $padding-side;
      }
    }
  }
  img.is-hidden {
    @include mq($until: tablet) {
      display: none;
    }
  }
  img {
    @include mq($from: desktop) {
      margin-bottom: $padding-side/2;
    }
  }
  .page-header-sponsors {
    @include mq($until: tablet) {
      margin-top: $padding-side;
    }
  }
  .page--studio & {
    .page-header-sponsors {
      margin-bottom: $padding-side*2;
    }
  }
}

.page-header--salon {
  .page-header__content {
    @include mq($from: desktop) {
      margin: 0;
      width: 50%;
    }
  }
  .page-header-grid-cell {
    @include mq($from: tablet) {
      margin-left: auto;
      width: 50%;
    }
    @include mq($from: desktop) {
      padding-left: 0;
      padding-right: 0;
      margin-left: auto;
      width: 75%;
    }
    @include mq($from: wide) {
      width: 50%;
    }
  }
  .page-header__title {
    flex: 1 0 auto;
  }
  .page-header__inner {
    align-items: initial;
    flex: 0 0 auto;
  }
  .page-header-sponsors {
    @include mq($from: desktop) {
      margin-top: 10vh;
      margin-bottom: 0;
    }
  }
}
