@import "styles/partials.scss";

.hotel-title {
  @include mq($from: tablet) {
    top: $hotel-title-top--tablet;
  }
  position: fixed;
  top: $hotel-title-top;
  left: 0;
  right: 0;
  transition: filter .6s $easeOutCubic, opacity .6s $easeOutCubic;
  z-index: $z-hotel-title;
  .is-blur & {
    opacity: 0.2;
    filter: blur(10px);
  }
}

.hotel-title__wrapper {
  @include wrapper($noMax: true);
}

.hotel-title__inner {
  position: relative;
}

// SECTION
.hotel-title-item {
  @include mq($from: tablet) {
    display: flex;
  }
  @include typography-headline();
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.hotel-title-item__num {
  margin-bottom: 8px;
  overflow: hidden;
  > div {
    @include mq($from: tablet) {
      transform: translateY(-100%);
    }
    transform: translateY(100%);
    transition: transform .3s $easeInOutCubic;
    .hotel-title-item.is-in & {
      transform: translateY(0);
    }
  }
}

// WRAPPER
.hotel-title-item__desktop {
  @include mq($until: tablet) {
    display: none;
  }
  transform: translateY(-3%);
  overflow: hidden;
  width: 100%;
}

.hotel-title-item__desktop-svg {
  backface-visibility: hidden;
  box-sizing: border-box;
  display: block;
  height: auto;
  transition: .3s transform $easeOutCubic;
  transform: translate3d(0,105%, 1px);
  width: auto;
  -webkit-backface-visibility: hidden;
  &.is-first {
    height: auto !important;
    width: 100%;
  }
  .hotel-title-item.is-in & {
    transform: translate3d(0, 0, 1px);
  }
}

// MOBILE
.hotel-title-item__mobile {
  @include mq($from: tablet) {
    display: none;
  }
}

.hotel-title-item__mobile-item {
  position: relative;
  margin-bottom: 3%;
  overflow: hidden;
  &:before {
    content: '';
    display: block;
    padding-bottom: 19%;
    width: 100%;
  }
}

.hotel-title-item__mobile-item-svg {
  left: 0;
  max-height: 100%;
  position: absolute;
  top: 0;
  transform: translateY(110%);
  transition: .3s transform $easeOutCubic;
  .hotel-title-item.is-in & {
    transform: translateY(0%);
  }
}