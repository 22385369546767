// hotel
$hotel-title-top: 88px;
$hotel-title-top--tablet: 72px;
// padding
$padding-side: 16px;
$padding-side--tablet: 24px;
$padding-sections: 56px;
$padding-sections--tablet: 80px;
$padding-sections--desktop: 96px;
// wrapper
$wrapper-max-width: 1600px;
// fluid typo
$fluid-type-max-vw: $wrapper-max-width;