@import "styles/partials";

.hotel {

}

.hotel__wrapper {
  height: 100vh;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  transition: height .2s $easeInOutCubic;
  transform: translateY(calc(100vh * (1 - var(--hotel-trigger-value))));
  width: 100%;
  z-index: $z-hotel-wrapper;
}

.hotel__container {
  @include mq($until: tablet) {
    bottom: 5vh;
    left: 50%;
    transform: translateX(-50%);
    width: 150%;
  }
  box-sizing: border-box;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  transition: transform 0.6s $easeOutCubic;
  width: 100%;
  z-index: $z-hotel-canvas;
  .is-blur & {
    @include mq($until: tablet) {
      transform: translateX(-50%) scale(1.2);
    }
    transform: scale(1.2);
  }
  canvas {
    @include mq($from: tablet) {
      top: 50%;
      transform: translate(-50%, -50%);
    }
    max-height: 1080px;
    max-width: 1920px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 0%);
    transition: filter 0.6s $easeOutCubic, opacity 0.6s $easeOutCubic;
    .is-blur & {
      filter: blur(10px);
      // opacity: 0.6;
    }
  }
  .hotel__scale {
    position: relative;
    height: 100%;
    transform: scale(var(--hotel-trigger-value));
    width: 100%;
    
  }
}
