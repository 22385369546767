@import "styles/partials.scss";

a,
button {
  &.button-move {
    @include typography-headline;
    align-self: flex-start;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;
  }
}

.button-move__area {
  overflow: hidden;
  .button-move.with-icon & {
    padding-right: 2.4em;
  }
}

.button-move__inner {
  height: 1em;
  transform: translateY(0em);
  transition: transform $a-button-move-d $a-button-move-f;
  .button-move:hover &,
  .button-move.is-active & {
    transform: translateY(-1em);
  }
  span {
    color: black;
    display: block;
    line-height: 1;
    &:last-child {
      color: $c-green;
    }
    .is-white & {
      color: $c-white !important;
    }
  }
}

.button-move__icon {
  border-top: 1px solid $c-black;
  border-right: 1px solid $c-black;
  position: absolute;
  right: 0;
  height: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  &:before {
    background: $c-black;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    right: 0;
    top: -1px;
    transform: rotate(-45deg);
    transform-origin: top right;
    width: 32px;
  }
  .button-move.is-white & {
    border-color: $c-white;
    &:before,
    &:after {
      background: $c-white;
    }
  }
}