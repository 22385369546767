@import "styles/partials.scss";

.cross {
  position: absolute;
  &:before {
    background: $c-black;
    content: "";
    display: block;
    height: 1px;
    left: -8px;
    position: absolute;
    top: 0;
    width: 17px;
  }
  &:after {
    background: $c-black;
    content: "";
    display: block;
    height: 17px;
    left: 0;
    top: -8px;
    position: absolute;
    width: 1px;
  }
  &.is-white {
    &:after,
    &:before {
      background: $c-white;
    }
  }
}
