@import "styles/partials.scss";

.image2k {
  overflow: hidden;
  position: relative;
  img {
    display: block;
    height: auto;
    max-width: 100%;
  }
}

.image2k-panels  {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  right: 0;
  > div {
    background: $c-white;
    transform: scale(1,1.1);
    transform-origin: 100% 0%;
    transition: transform $easeInOutCubic;
    width: 110%;
  }
  .image2k.is-in & {
    > div {
      transform: scale(1,0);
      transform-origin: 100% 100%;
      transition-timing-function: $easeInOutCubic;
    }
  }
}