@import "styles/partials";

.hotel-title-num {
  @include mq($from: tablet) {
    font-size: 1em;
  }
  @include typography-headline();
  align-items: center;
  background: $c-black;
  color: $c-white;
  display: flex;
  font-size: .7em;
  letter-spacing: .05em;
  height: 1.4em;
  // position: absolute;
  justify-content: center;
  margin-right: .5em;
  width: 2.6em;
}