@import "styles/partials.scss";

.teaser--exhibitions {
  .teaser-block {
    @include mq($from: desktop) {
      width: 33.33%;
    }
    @include mq($from: wide) {
      width: 25%;
    }
  }
  .teaser-block--image {
    &:nth-child(3) {
      @include mq($until: desktop) {
        margin-left: -$padding-side/2;
        margin-top: 48px;
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: calc(100% + #{$padding-side});
      }
      @include mq($from: wide) {
        width: 50%;
        img {
          max-width: 75%;
        }
      }
    }
  }
  .teaser-block--copy {
    @include mq($until: desktop) {
      margin-top: $padding-side;
    }
  }
  .teaser-cross--copy,
  .teaser-cross--img,
  .teaser-cross--img-2 {
    top: 0;
  }
  .teaser-cross--img
   {
    @include mq($until: desktop) {
      left: 0;
      right: initial;
    }
  }
  .teaser-cross--img-2 {
    @include mq($from: desktop) {
      display: none;
    }
    right: 0;
  }
  .teaser-cross--copy {
    @include mq($until: desktop) {
      display: none;
    }
  }
  h5 {
    @include mq($until: desktop) {
      margin-bottom: $padding-sections;
    }
  }
  .teaser-block-button {
    margin-top: 24px;
  }
  &.is-img-1 {
    .teaser-block--image {
      @include mq($from: desktop) {
        width: 50%;
      }
    }
  }
  &.is-img-big {
    .teaser-block--image {
      @include mq($from: desktop) {
        width: 75%;
      }
    }
  }
  &:nth-child(even) {
    .teaser-block--image {
      &:nth-child(3) {
        img {
          @include mq($from: desktop) {
            margin-left: auto;
          }
        }
      }
    }
    .teaser-cross--copy {
      right: 0;
    }
    .teaser-cross--img {
      left: 0;
    }
  }
  &:nth-child(odd) {
    .teaser__row {
      @include mq($from: desktop) {
        flex-direction: row-reverse;
      }
    }
    h5 {
      @include mq($from: desktop) {
        text-align: right;
      }
    }
    .teaser-cross--copy {
      left: 0;
    }
    .teaser-cross--img {
      right: 0;
    }
  }
}

.teaser-sponsor-icon {
  height: 24px;
  margin-bottom: 24px;
  img {
    height: 100%;
    width: auto;
  }
}

.teaser-block__inner {
  opacity: 0;
  transform: translateX(-50%);
  transition: all 0.6s $easeOutSine;
  .teaser.is-in & {
    opacity: 1;
    transform: translateY(0);
    transition-delay:  0.2s;
  }
  .teaser:nth-child(odd) {
    transform: translateX(50%);
  }
}