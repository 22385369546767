@import "styles/partials.scss";

.transition {
  height: var(--win-height, 100vh);
  left: 0;
  position: fixed;
  pointer-events: none;
  top: 0;
  width: 100%;
  z-index: $z-transition;
  > div {
    background: $c-green;
    transform: scale(1,0);
    transform-origin: 100% 0%;
    transition: transform $easeInOutCubic;
  }
  &.is-open {
    > div {
      opacity: 1;
      transform: scale(1,1.05);
      transform-origin: 100% 100%;
      transition-timing-function: $easeInOutCubic;
    }
  }
}
