@import "styles/partials.scss";

.teaser--salon {
  .teaser-block {
    @include mq($from: desktop) {
      width: 33.33%;
    }
  }
  .teaser-group {
    @include mq($from: desktop) {
      flex-direction: row;
      width: 66.66%;
    }
    display: flex;
    flex-direction: column;
    .teaser-block {
      @include mq($from: desktop) {
        width: 50%;
      }
    }
  }
  .teaser-block--title {
    margin-bottom: $padding-sections;
  }
  .teaser-button {
    margin-top: $padding-side--tablet;
    margin-bottom: $padding-side--tablet*2;
  }
  .teaser-block__image-bottom {
    @include mq($until: tablet) {
      margin-left: -$padding-side;
      margin-right: -$padding-side;
    }
    @include mq($until: desktop) {
      margin-left: -$padding-side--tablet;
      margin-right: -$padding-side--tablet;
    }
    margin-top: auto;
  }
  .teaser-cross--img {
    left: 0;
    top: 0;
  }
  .teaser-cross--img-2 {
    right: 0;
    top: 0;
  }
  //
  &.is-reverse {
    .teaser__row {
      @include mq($from: desktop) {
        flex-direction: row-reverse;
      }
    }
    .teaser-cross--img {
      right: 0;
      left: initial;
    }
    .teaser-cross--img-2 {
      right: initial;
      left: 0;
    }
  }
}