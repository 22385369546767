@import "styles/partials.scss";

.scroll {
  position: relative;
  z-index: $z-scroll;
}

.scroll--page {
  // pointer-events: none;
}

.scroll__scrollable {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
