@import "styles/partials.scss";

.schedule-teaser {
  @include mq($from: desktop) {
    border: none;
    margin-top: 0;
  }
  border-bottom: 1px solid $c-black;
  margin-top: 40px;
  &:first-child {
    margin-top: 0;
  }
  &:nth-child(2) {
    
  }
  p.small {
    font-size: 0.6em;
    text-transform: uppercase;
  }
}

.schedule-teaser__wrapper {
  @include wrapper();
}

.schedule-teaser__inner {
  @include mq($from: desktop) {
    flex-direction: row;
    margin-left: -$padding-side--tablet/2;
    margin-right: -$padding-side--tablet/2;
  }
  display: flex;
  flex-direction: column;
  margin-left: -$padding-side/2;
  margin-right: -$padding-side/2;
}

.schedule-teaser__row {
  @include mq($from: desktop) {
    margin-bottom: 124px;
    flex-wrap: nowrap;
  }
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.schedule-teaser__box {
  @include mq($from: desktop) {
    margin-bottom: 0;
    padding-left: $padding-side--tablet/2;
    padding-right: $padding-side--tablet/2;
  }
  box-sizing: border-box;
  margin-bottom: 24px;
  padding-left: $padding-side/2;
  padding-right: $padding-side/2;
}

// TIME
$schedule-time-padding: 28px;
.schedule-teaser-time {
  @include mq($from: desktop) {
    flex: 0 0 212px;
  }
  box-sizing: border-box;
  position: relative;
}

.schedule-teaser-time__inner {
  @include mq($from: desktop) {
    padding-left: $schedule-time-padding;
  }
  position: relative;
  height: 100%
}

.schedule-teaser-time__time {
  @include font-size("schedule-time");
  @include typography-headline();
  line-height: 1;
  small {
    font-size: 0.5em;
  }
}

// DATE
.schedule-teaser-date {
  @include mq($from: desktop) {
    margin-left: initial;
    margin-right: initial;
    margin-bottom: 0;
    position: absolute;
    top: -8px;
    padding-left: $schedule-time-padding;
    transform: translateY(-113%);
  }
  background: $c-black;
  box-sizing: border-box;
  color: $c-white;
  font-weight: 700;
  left: 0;
  margin-left: -$padding-side;
  margin-right: -$padding-side;
  margin-bottom: 16px;
  padding: 4px 4px 4px $padding-side;
  right: 0;
  text-transform: uppercase;
}

.schedule-teaser-time__markers {
  @include mq($from: desktop) {
    display: flex;
  }
  display: none;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  > div {
    border-bottom: 1px solid $c-black;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 22px;
    &:last-child {
      border: none;
    }
    > div {
      border-bottom: 1px solid $c-black;
      flex: 1 0 auto;
      width: 11px;
      &:last-child {
        border: none;
      }
    }
  }
  &:before {
    background: $c-black;
    height: 2px;
    content: '';
    display: block;
    width: 24px;
  }
}

// SPEAKER
.schedule-teaser-speaker {
  @include mq($from: tablet) {
    flex-basis: 50%;
  }
  @include mq($from: desktop) {
    .schedule-teaser__count-1 & {
      margin-left: 30%;
    }
    flex-basis: 30%;
  }
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  justify-content: space-between;
  img {
    display: block;
    height: auto;
    max-width: 100%;
  }
}

.schedule-teaser-speaker__name {
  @include mq($from: desktop) {
    margin-bottom: 8px;
  }
  margin-bottom: 8px;
}

// CONTENT
.schedule-teaser-content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: flex-end;
}

.schedule-teaser-content__title {
  @include mq($from: tablet) {
    margin-bottom: 24px;
  }
  margin-bottom: 16px;
}

.schedule-teaser-content__sponsor {
  height: 24px;
  width: auto;
}