@import "styles/partials";

.lobby3d-canvas {
  height: 100vh;
  width: 100vw;
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  z-index: $z-lobby-3d;
  filter: blur(10px);
  opacity: 0;
  transition: $a-transition-blur-canvas;
  .lobby.is-init & {
    filter: blur(0px);
    opacity: 1;
  }
  .is-topbar-transparent & {
    z-index: $z-lobby-3d-overlay;
  }
  .is-mobile & {
    z-index: $z-lobby-3d;
  }
}
